import axios from 'axios'

export default {
  getSaleDars (data) {
    return axios.get('/dars?date=' + data.date + '&dgid=' + data.dgid)
  },
  getGroupSummary (data) {
    return axios.get('/dar_group_summary_by_store?date=' + data.date + '&dgid=' + data.dgid)
  },
  getDarStatus (data) {
    return axios.get('/dar_status?dgid=' + data.dgid)
  },
  getServiceDars (data) {
    return axios.get('/dar_services?date=' + data.date + '&dgid=' + data.dgid)
  },
  uploadSeServiceFix (data, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', data)

    return axios.post('/dars/upload_se_service_fix', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },
  uploadSalesFile (data, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', data)

    return axios.post('/dars/upload_sales_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },
  uploadServiceFile (data, onUploadProgress) {
    const formData = new FormData()
    formData.append('file', data)

    return axios.post('/dars/upload_service_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },
}
