/* eslint-disable */
<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
  <template v-if="!loading">
  <div id="pdf_loader">
    <table
      align="left"
      cellspacing="0"
      border="0"
      >
      <colgroup width="65"></colgroup>
      <colgroup span="7" width="64"></colgroup>
      <colgroup width="79"></colgroup>
      <colgroup width="64"></colgroup>
      <colgroup width="10"></colgroup>
      <colgroup span="2" width="57"></colgroup>
      <colgroup width="10"></colgroup>
      <colgroup width="39"></colgroup>
      <colgroup span="5" width="57"></colgroup>
      <colgroup width="67"></colgroup>
      <tr>
        <td style="border-left: 3px solid #000000; border-right: 3px solid #000000" align="center" bgcolor="#000000">
          <img src="/images/kag_logo.png" width=192 height=67>
        </td>
        <td style="border-left: 3px solid #000000; border-right: 3px solid #000000" colspan=14 align="center" bgcolor="#000000">
          <b>
          <font size=5 color="#FFFFFF">
            Daily Activity Report - Service & Parts (CP & W)
            <br />
            <b><font size=3 color="#FFFFFF">{{ formatFullDate(date) }}</font></b>
          </font></b></td>
        <td align="left"><br></td>
      </tr>
      <tr>
        <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" height="30" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">DATE</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">APPTS</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">TOTAL SHOWS</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">RO'S CLOSED</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">HOURS SOLD</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">$/RO</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333" sdnum="1033;0;0%"><b><font color="#FFFFFF">TOTAL GROSS</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000; border-left: 3px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF"><br></font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Appts</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Shows</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">ROs Closed</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Hours Sold</font></b></td>
        <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" bgcolor="#333333"><b><font color="#FFFFFF">Gross</font></b></td>
        <td align="left"><br></td>
      </tr>

      <template v-for="(item, index) in items">
        <!-- FIRST DATA ROW HERE -->
        <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
          <td style="border-top: 0px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000" rowspan=3 align="center" valign=middle :bgcolor="rowBgColor(index)"><b>{{ item.account_abbrev }}</b></td>
          <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="44125" sdnum="1033;1033;D-MMM"><b>{{ formatAbbreviatedDate(item.dar.activity_date, false, '-') }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="11" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.appts) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="18" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.total_shows) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="0.56" sdnum="1033;0;0%"><b>{{ formatNumber(item.dar.ros_closed) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="10" sdnum="1033;0;0"><b>{{ formatNumber(item.dar.hours_sold) }}</b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="7" sdnum="1033;0;0"><b>{{ formatPrice(item.dar.dollars_per_ro) }} </b></td>
          <td style="border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="3" sdnum="1033;0;0"><b>{{ formatPrice(item.dar.total_gross) }}</b></td>
          <template v-if="showPaceRow()">
            <td style="border-top: 0px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_appts)">{{ formatNumber(item.monthly_dar.mom_appts) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_shows)">{{ formatNumber(item.monthly_dar.mom_shows) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_ros_closed)">{{ formatNumber(item.monthly_dar.mom_ros_closed) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_hours_sold)">{{ formatNumber(item.monthly_dar.mom_hours_sold) }} %</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.mom_total_gross)">{{ formatNumber(item.monthly_dar.mom_total_gross) }} %</font></b></td>
            <td align="left"><br></td>
          </template>
          <template v-else>
            <td style="border-top: 0px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 0px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td align="left"><br></td>
          </template>
        </tr>
        <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b>Totals</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="242" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.appts) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="405" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.total_shows) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="0.348148148148148" sdnum="1033;0;0%"><b>{{ formatNumber(item.monthly_dar.ros_closed) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="141" sdnum="1033;0;0"><b>{{ formatNumber(item.monthly_dar.hours_sold) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="96" sdnum="1033;0;0"><b>{{ formatPrice(item.monthly_dar.dollars_per_ro) }}</b></td>
          <td style="border-top: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000; border-bottom: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdval="45" sdnum="1033;0;0"><b>{{ formatPrice(item.monthly_dar.total_gross) }}</b></td>
          <template v-if="showPaceRow()">
            <td align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
            <!-- <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_phone)">{{ formatNumber(item.monthly_dar.yoy_phone) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_traffic)">{{ formatNumber(item.monthly_dar.yoy_traffic) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_delivered)">{{ formatNumber(item.monthly_dar.yoy_delivered) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_gross)">{{ formatNumber(item.monthly_dar.yoy_gross) }} %</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_closing_percent)">{{ formatNumber(item.monthly_dar.yoy_closing_percent) }} %</font></b></td> -->

            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_phone)"></font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_traffic)"></font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_delivered)"></font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_gross)"></font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font :color="comparisonColor(item.monthly_dar.yoy_closing_percent)"></font></b></td>


            <td align="left"><br></td>
          </template>
          <template v-else>
            <td align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 3px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b></b></td>
            <td align="left"><br></td>
          </template>
        </tr>
        <tr :key="Math.floor(Math.random() * (100000 - 1 + 1)) + item.account_id">
          <td style="border-bottom: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;1033;D-MMM"><b><br></b></td>
          <td style="border-bottom: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
          <td style="border-bottom: 3px solid #000000; border-right: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b><br></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 1px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)" sdnum="1033;0;0"><b>Pace</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000" align="center" valign=middle bgcolor="#FFFFCC" sdval="211.5" sdnum="1033;0;#,##0"><b>{{ formatNumber( item.monthly_dar.hours_sold_pace) }}</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000" align="center" valign=middle bgcolor="#FFFFCC" sdval="144" sdnum="1033;0;0"><b>--</b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000" align="center" valign=middle bgcolor="" sdval="67.5" sdnum="1033;0;0"><b>{{ formatPrice( item.monthly_dar.total_gross_pace) }}</b></td>
          <td style="border-bottom: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><br></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 3px solid #000000; border-right: 3px solid #000000" align="center" valign=middle :bgcolor="rowBgColor(index)"><b><font color="#FF0000"><br></font></b></td>
          <td align="left"><br></td>
        </tr>        
      </template>

      <!-- END OF DATA -->
      <tr>
        <td style="border-top: 3px solid #000000; border-left: 3px solid #000000; border-right: 3px solid #000000" colspan=15 height="23" align="center" valign=middle bgcolor="#333333"><b><font size=3 color="#FFFFFF">KAG Totals</font></b></td>
        <td align="left"><br></td>
      </tr>
      <tr>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 3px solid #000000; border-right: 2px solid #000000" height="18" align="center" valign=middle bgcolor="#FFFFFF"><b>Day</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF"><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="108" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_appts) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="211" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_total_shows) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="0.442222222222222" sdnum="1033;0;0%"><b>{{ formatNumber(summary.daily_ros_closed) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="92" sdnum="1033;0;0"><b>{{ formatNumber(summary.daily_hours_sold) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="67" sdnum="1033;0;0"><b>{{ formatPrice(summary.daily_dollars_per_ro) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="25" sdnum="1033;0;0"><b>{{ formatPrice(summary.daily_total_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="281412" sdnum="1033;0;&quot;$&quot;#,##0"><b></b></td>
        <template v-if="showPaceRow()">
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_appts)">{{ formatNumber(summary.mom_appts) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_shows)">{{ formatNumber(summary.mom_shows) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_ros_closed)">{{ formatNumber(summary.mom_ros_closed) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_hours_sold)">{{ formatNumber(summary.mom_hours_sold) }} %</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.mom_total_gross)">{{ formatNumber(summary.mom_total_gross) }} %</font></b></td>
          <td align="left"><br></td>
        </template>
        <template v-else>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">MOM</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b></b></td>
          <td align="left"><br></td>
        </template>
      </tr>
      <tr>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 3px solid #000000; border-right: 2px solid #000000" height="18" align="center" valign=middle><b>MTD</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="2335" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_appts) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="4778" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_total_shows) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="0.474071533287137" sdnum="1033;0;0%"><b>{{ formatNumber(summary.monthly_ros_closed) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="2113" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.monthly_hours_sold) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="1373" sdnum="1033;0;#,##0"><b>{{ formatPrice(summary.monthly_dollars_per_ro) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="740" sdnum="1033;0;#,##0"><b>{{ formatPrice(summary.monthly_total_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 2px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle bgcolor="#FFFFFF" sdval="7237760" sdnum="1033;0;&quot;$&quot;#,##0"><b></b></td>
        <template v-if="showPaceRow()">        
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_phone)"></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_traffic)"></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_delivered)"></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_gross)"></font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b><font :color="comparisonColor(summary.yoy_closing_percent)"></font></b></td>
          <td align="left"><br></td>
        </template>
        <template v-else>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle bgcolor="#808080"><b><font color="#FFFFFF">YOY</font></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 1px solid #000000" align="center" valign=middle><b></b></td>
          <td style="border-top: 1px solid #000000; border-bottom: 1px solid #000000; border-left: 1px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><b></b></td>
          <td align="left"><br></td>
        </template>
      </tr>
      <tr>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 3px solid #000000; border-right: 2px solid #000000" height="18" align="center" valign=middle><b>Pace</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle><br></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="3502.5" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_appts) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="7167" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_total_shows) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdnum="1033;0;0%"><b>{{ formatNumber(summary.pace_ros_closed) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="3169.5" sdnum="1033;0;#,##0"><b>{{ formatNumber(summary.pace_hours_sold) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="2059.5" sdnum="1033;0;#,##0"><b>--</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="1110" sdnum="1033;0;#,##0"><b>{{ formatPrice(summary.pace_total_gross) }}</b></td>
        <td style="border-top: 2px solid #000000; border-bottom: 3px solid #000000; border-left: 2px solid #000000; border-right: 2px solid #000000" align="center" valign=middle sdval="10856640" sdnum="1033;0;&quot;$&quot;#,##0"><b></b></td>
        <td style="border-bottom: 3px solid #000000; border-left: 2px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000" align="center" valign=middle><br></td>
        <td style="border-bottom: 3px solid #000000; border-right: 3px solid #000000" align="center" valign=middle><br></td>
        <td align="left"><br></td>
      </tr>
      <tr>
        <td colspan="9">
          <br/>Powered by Dealer Insights, LLC.
        </td>
      </tr>
    </table>
  </div>
  </template>
  </v-container>
</template>
<script>
  import DarService from '@/services/DarService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'

  export default {
    name: 'SalesDar',
    components: {
    },
    mixins: [formatterMixin],
    data () {
      return {
        items: [],
        loading: true,
        error: '',
        date: null,
      }
    },
    computed: {
      ...mapGetters(['accountId', 'accountName']),
    },
    created () {
      this.loadDars()
    },
    methods: {
      loadDars: function () {
        this.loading = true
        this.items = []
        this.summary = []
        
        var data = {
          dgid: this.$route.query.dgid,
        }

        DarService.getServiceDars(data)
          .then(response => {
            this.items = response.data.dars
            this.date = this.items[0].dar.activity_date
            this.summary = response.data.summary
            console.log(response.data)
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            if (!error.response) {
              this.error = 'Network Error. Check your internet connection.'
            } else {
              var errorMessage = error.response.data.error
              this.error = errorMessage
            }
          })
      },
      rowBgColor(index) {
        if (index%2 == 0)
          return "#C0C0C0"
        else
          return false;
        return "#FFFFFF"
      },
      profitBgColor (profit, index) {
        if (profit === null)
          return this.rowBgColor(index)
        if (profit >= 3000) 
          return "#C7EFCF"
        if (profit >= 2500 && profit < 3000)        
          return "#C6D9F0"
        if (profit >= 2000 && profit < 2500)        
          return "#FAD5B6"
        if (profit < 2000)
          return "#FDC7CE"
      },
      comparisonColor (percentChange) {
        if (percentChange > 1)
          return "#1AAF54"
        if (percentChange >= -9 && percentChange <= 1)
          return "#976513"
        if (percentChange < -9)
          return "#FB0219"
      },
      showPaceRow () {
        const [year, month, day] = this.date.split('-')
        if (day < 9)
          return false
        else
          return true
      }
    },
  }
</script>